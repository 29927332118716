import loadImages from "./image-loader";
import times from "./times.js";
import createCanvas from "./create-canvas.js";
import {random, chance} from "./random";

let dropSize=64;
let Drop={
  x:0,
  y:0,
  r:0,
  spreadX:0,
  spreadY:0,
  momentum:0,
  momentumX:0,
  lastSpawn:0,
  nextSpawn:0,
  parent:null,
  isNew:true,
  killed:false,
  tension:0,
  shrink:0,
}
function Raindrops(width,height,scale,dropAlpha,dropColor){
  this.width=width;
  this.height=height;
  this.scale=scale;
  this.dropAlpha=dropAlpha;
  this.dropColor=dropColor;
  this.init();
}

Raindrops.prototype={
  canvas:null,
  ctx:null,
  width:0,
  height:0,
  scale:0,
  minR:10,
  maxR:40,
  maxDrops:800,
  texture:null,
  textureCtx:null,
  texturePixelDensity:1,
  rainChance:0.3,
  rainLimit:3,
  drizzleCounter:0,
  drizzle:50,
  drizzleSize:[2,4],
  drizzleCleaningRadiusMultiplier:0.45,
  drops:null,
  dropsGfx:null,
  paintGfx:null,
  raining:true,
  lastRender:null,
  textureCleaningIterations:0,
  globalTimeScale:1,
  trailRate:1,
  autoShrink:true,
  spawnArea:[-0.1,0.95],
  trailScaleRange:[0.2,0.5],
  collisionRadius:0.65,
  collisionRadiusIncrease:0.01,
  dropFallMultiplier:1,
  init(){
    this.canvas = createCanvas(this.width,this.height);
    this.ctx=this.canvas.getContext('2d');

    this.texture = createCanvas(this.width*this.texturePixelDensity,this.height*this.texturePixelDensity);
    this.textureCtx=this.texture.getContext('2d');

    this.drops=[];
    this.newDrops=[];
    this.dropsGfx=[];

    this.renderDropsGfx();
  },
  get deltaR(){
    return this.maxR-this.minR;
  },
  get area(){
    return (this.width*this.height)/this.scale;
  },
  get areaMultiplier(){
    return this.area/(1024*768);
  },
  drawTextureDrop(x,y,r){
    this.drawDrop(this.textureCtx,Object.assign(Object.create(Drop),{
      x:x*this.texturePixelDensity,
      y:y*this.texturePixelDensity,
      r:r*this.texturePixelDensity
    }));
  },

  renderDropsGfx(){
    let dropBuffer=createCanvas(dropSize,dropSize);
    let dropBufferCtx=dropBuffer.getContext('2d');
    this.dropsGfx=Array.apply(null,Array(255))
      .map((cur,i)=>{
        let drop=createCanvas(dropSize,dropSize);
        let dropCtx=drop.getContext('2d');

        dropBufferCtx.clearRect(0,0,dropSize,dropSize);

        // color
        dropBufferCtx.globalCompositeOperation="source-over";
        dropBufferCtx.drawImage(this.dropColor,0,0,dropSize,dropSize);

        // blue overlay, for depth
        dropBufferCtx.globalCompositeOperation="screen";
        dropBufferCtx.fillStyle="rgba(0,0,"+i+",1)";
        dropBufferCtx.fillRect(0,0,dropSize,dropSize);

        // alpha
        dropCtx.globalCompositeOperation="source-over";
        dropCtx.drawImage(this.dropAlpha,0,0,dropSize,dropSize);

        dropCtx.globalCompositeOperation="source-in";
        dropCtx.drawImage(dropBuffer,0,0,dropSize,dropSize);
        return drop;
    });

    // create circle that will be used as a brush to remove droplets
    this.paintGfx=createCanvas(128,128);
    let paintCtx=this.paintGfx.getContext("2d");
    paintCtx.fillStyle="#000";
    paintCtx.beginPath();
    paintCtx.arc(64,64,64,0,Math.PI*2);
    paintCtx.fill();

    this.update();

  },
  drawDrop(ctx,drop){
    if(this.dropsGfx.length>0){
      let x=drop.x;
      let y=drop.y;
      let r=drop.r;
      let spreadX=drop.spreadX;
      let spreadY=drop.spreadY;

      let scaleX=1;
      let scaleY=1.5;

      let d=Math.max(0,Math.min(1,((r-this.minR)/(this.deltaR))*0.9));
      d*=1/(((drop.spreadX+drop.spreadY)*0.5)+1);

      ctx.globalAlpha=1;
      ctx.globalCompositeOperation="source-over";

      d=Math.floor(d*(this.dropsGfx.length-1));
      ctx.drawImage(
        this.dropsGfx[d],
        (x-(r*scaleX*(spreadX+1)))*this.scale,
        (y-(r*scaleY*(spreadY+1)))*this.scale,
        (r*2*scaleX*(spreadX+1))*this.scale,
        (r*2*scaleY*(spreadY+1))*this.scale
      );
    }
  },
  paint(x,y,r=30){
    let ctx=this.textureCtx;
    ctx.globalCompositeOperation="destination-out";
    ctx.drawImage(
      this.paintGfx,
      (x-r)*this.texturePixelDensity*this.scale,
      (y-r)*this.texturePixelDensity*this.scale,
      (r*2)*this.texturePixelDensity*this.scale,
      (r*2)*this.texturePixelDensity*this.scale*1.5
    )
  },
  clearCanvas(){
    this.ctx.clearRect(0,0,this.width,this.height);
  },
  createDrop(options){
    if(this.drops.length >= this.maxDrops) return null;

    return Object.assign(Object.create(Drop),options);
  },
  addDrop(drop){
    if(this.drops.length >= this.maxDrops || drop==null) return false;

    this.drops.push(drop);
    return true;
  },
  updateRain(timeScale){
    let rainDrops=[];
    if(this.raining){
      let limit=this.rainLimit*timeScale*this.areaMultiplier;
      let count=0;
      while(chance(this.rainChance*timeScale*this.areaMultiplier) && count<limit){
        count++;
        let r=random(this.minR,this.maxR,(n)=>{
          return Math.pow(n,3);
        });
        let rainDrop=this.createDrop({
          x:random(this.width/this.scale),
          y:random((this.height/this.scale)*this.spawnArea[0],(this.height/this.scale)*this.spawnArea[1]),
          r:r,
          momentum:1+((r-this.minR)*0.3)+random(0.5),
          spreadX:1.5,
          spreadY:1.5,
        });
        if(rainDrop!=null){
          rainDrops.push(rainDrop);
        }
      }
    }
    return rainDrops;
  },
  clearDrops(){
    this.drops.forEach((drop)=>{
      setTimeout(()=>{
        drop.shrink=0.1+(random(0.5));
      },random(1200))
    })
    this.clearTexture();
  },
  clearTexture(){
    this.textureCleaningIterations=50;
  },
  updateTexture(timeScale){
    if(this.textureCleaningIterations>0){
      this.textureCleaningIterations-=1*timeScale;
      this.textureCtx.globalCompositeOperation="destination-out";
      this.textureCtx.fillStyle="rgba(0,0,0,"+(0.05*timeScale)+")";
      this.textureCtx.fillRect(0,0,
        this.width*this.texturePixelDensity,this.height*this.texturePixelDensity);
    }
    if(this.raining){
      this.drizzleCounter+=this.drizzle*timeScale*this.areaMultiplier;
      times(this.drizzleCounter,(i)=>{
        this.drizzleCounter--;
        this.drawTextureDrop(
          random(this.width/this.scale),
          random(this.height/this.scale),
          random(...this.drizzleSize,(n)=>{
            return n*n;
          })
        )
      });
    }
    this.ctx.drawImage(this.texture,0,0,this.width,this.height);
  },
  updateDrops(timeScale){
    let newDrops=[];

    this.updateTexture(timeScale);
    let rainDrops=this.updateRain(timeScale);
    newDrops=newDrops.concat(rainDrops);

    this.drops.sort((a,b)=>{
      let va=(a.y*(this.width/this.scale))+a.x;
      let vb=(b.y*(this.width/this.scale))+b.x;
      return va>vb?1:va==vb?0:-1;
    });

    this.drops.forEach(function(drop,i){
      if(!drop.killed){
        // update gravity
        if(chance((drop.r-(this.minR*this.dropFallMultiplier)) * (0.1/this.deltaR) * timeScale)){
          drop.momentum += random((drop.r/this.maxR)*4);
        }
        // clean small drops
        if(this.autoShrink && drop.r<=this.minR && chance(0.05*timeScale)){
          drop.shrink+=0.01;
        }
        //update shrinkage
        drop.r -= drop.shrink*timeScale;
        if(drop.r<=0) drop.killed=true;

        // update trails
        if(this.raining){
          drop.lastSpawn+=drop.momentum*timeScale*this.trailRate;
          if(drop.lastSpawn>drop.nextSpawn){
            let trailDrop=this.createDrop({
              x:drop.x+(random(-drop.r,drop.r)*0.1),
              y:drop.y-(drop.r*0.01),
              r:drop.r*random(...this.trailScaleRange),
              spreadY:drop.momentum*0.15,
              parent:drop,
            });

            if(trailDrop!=null){
              newDrops.push(trailDrop);

              drop.r*=Math.pow(0.97,timeScale);
              drop.lastSpawn=0;
              drop.nextSpawn=random(this.minR,this.maxR)-(drop.momentum*2*this.trailRate)+(this.maxR-drop.r);
            }
          }
        }

        //normalize spread
        drop.spreadX*=Math.pow(0.4,timeScale);
        drop.spreadY*=Math.pow(0.7,timeScale);

        //update position
        let moved=drop.momentum>0;
        if(moved && !drop.killed){
          drop.y+=drop.momentum;
          drop.x+=drop.momentumX;
          if(drop.y>(this.height/this.scale)+drop.r){
            drop.killed=true;
          }
        }

        // collision
        let checkCollision=(moved || drop.isNew) && !drop.killed;
        drop.isNew=false;

        if(checkCollision){
          this.drops.slice(i+1,i+70).forEach((drop2)=>{
            //basic check
            if(
              drop != drop2 &&
              drop.r > drop2.r &&
              drop.parent != drop2 &&
              drop2.parent != drop &&
              !drop2.killed
            ){
              let dx=drop2.x-drop.x;
              let dy=drop2.y-drop.y;
              var d=Math.sqrt((dx*dx)+(dy*dy));
              //if it's within acceptable distance
              if(d<(drop.r+drop2.r)*(this.collisionRadius+(drop.momentum*this.collisionRadiusIncrease*timeScale))){
                let pi=Math.PI;
                let r1=drop.r;
                let r2=drop2.r;
                let a1=pi*(r1*r1);
                let a2=pi*(r2*r2);
                let targetR=Math.sqrt((a1+(a2*0.8))/pi);
                if(targetR>this.maxR){
                  targetR=this.maxR;
                }
                drop.r=targetR;
                drop.momentumX+=dx*0.1;
                drop.spreadX=0;
                drop.spreadY=0;
                drop2.killed=true;
                drop.momentum=Math.max(drop2.momentum,Math.min(40,drop.momentum+(targetR*0.04)+1));
              }
            }
          });
        }

        //slowdown momentum
        drop.momentum-=Math.max(1,(this.minR*0.5)-drop.momentum)*0.1*timeScale;
        if(drop.momentum<0) drop.momentum=0;
        drop.momentumX*=Math.pow(0.7,timeScale);


        if(!drop.killed){
          newDrops.push(drop);
          if(moved && this.drizzle>0) this.paint(drop.x,drop.y,drop.r*this.drizzleCleaningRadiusMultiplier);
          this.drawDrop(this.ctx,drop);
        }

      }
    },this);

    this.drops = newDrops;
  },
  update(){
    this.clearCanvas();

    let now=Date.now();
    if(this.lastRender==null) this.lastRender=now;
    let deltaT=now-this.lastRender;
    let timeScale=deltaT/((1/60)*1000);
    if(timeScale>2) timeScale=2;
    timeScale*=this.globalTimeScale;
    this.lastRender=now;

    this.updateDrops(timeScale);

    requestAnimationFrame(this.update.bind(this));
  }
}

export default Raindrops;
